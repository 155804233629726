import React, { Component } from "react";
import { API, Helpers, Router } from '../Helpers';
import { InputHidden, InputText, Select, TextArea } from '../Core';
import SortableTree from "react-sortable-tree";
import Vapor from 'laravel-vapor';


const custom_link_types = ['custom_url', 'list_item'];

class MenuEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_items: this.defaultMenuItems(),
            working_category: 'home',
            selected_pages: [],
            custom_type: 'custom_url'
        };

        this.addCustomLinkToMenu = this.addCustomLinkToMenu.bind(this);
        this.addPagesToMenu = this.addPagesToMenu.bind(this);
        this.clearSelectedPages = this.clearSelectedPages.bind(this);
        this.defaultMenuItems = this.defaultMenuItems.bind(this);
        this.findSiteSetting = this.findSiteSetting.bind(this);
        this.removeFromMenu = this.removeFromMenu.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.setMenuItems = this.setMenuItems.bind(this);
        this.toggleSelectPage = this.toggleSelectPage.bind(this);
        this.unselectPage = this.unselectPage.bind(this);
        this.updateMenuItem = this.updateMenuItem.bind(this);
        this.toggleNodeExpansion = this.toggleNodeExpansion.bind(this);
        this.updateNodeExpansion = this.updateNodeExpansion.bind(this);
        this.handleMenuChange = this.handleMenuChange.bind(this);
    }

    // SYSTEM
    findSiteSetting(key) {
        let setting = _.find(this.props.data.active_site.settings, (setting) => { return setting.key == key; });

        if (!_.isEmpty(setting)) {
            return setting.value;
        }
    }
    // CUSTOM PAGES
    addCustomLinkToMenu(event) {
        event.preventDefault();

        const form = event.target;
        const formData = new FormData(form);

        let menu_items = [...this.workingCategoryDirectoryMenus()];
        let menu_item = Helpers.serializeFormData(formData);
        menu_item = _.merge(menu_item, { id: uniqid() });

        if (menu_item.menu_parent === '-1') {
            menu_items.push(menu_item);
        } else {
            // Function to recursively search and add child
            const addChildToParent = (items) => {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].id + '' === menu_item.menu_parent + '') {
                        if (!items[i].children) {
                            items[i].children = [];
                        }
                        items[i].children.push(menu_item);
                        return true;
                    }
                    if (items[i].children) {
                        if (addChildToParent(items[i].children)) {
                            return true;
                        }
                    }
                }
                return false;
            };

            addChildToParent(menu_items);
        }

        this.setMenuItems({
            ...this.state.menu_items,
            [this.state.working_category]: menu_items,
        });


        this.setState({
            custom_type: 'custom_url'
        }, () => {
            // Clear the form
            form.reset();
        })


    }
    clearSelectedPages() {
        this.setState({
            selected_pages: []
        });
    }
    addPagesToMenu() {
        console.log("addPagesToMenu");

        const router = Router(this.props.data.routes);

        let menu_items = [...this.workingCategoryDirectoryMenus()];


        _.forEach(this.state.selected_pages, (page) => {

            let path = '/' + page.page.slug;
            let homepage = _.find(this.props.data.active_site.settings.data, (setting) => { return setting.key == 'homepage'; });

            if (!_.isEmpty(homepage)) {
                if (homepage.value == page.page.slug) {
                    //path += page.slug;

                    path = "/";
                }
            }

            menu_items.push({
                id: page?.page?.id || uniqid(),
                url: path, //router.url('customer.pages.show', {domain: this.props.data.active_site.domain, page: page.slug}),
                title: page.page.title,
                navigation_label: page.page.title,
                type: 'page',
                page_id: page.page.id,
                expanded: true,
                isExpanded: false,
                children: []
            });
        });

        this.setMenuItems({
            ...this.state.menu_items,
            [this.state.working_category]: menu_items
        }, true);
    }
    toggleSelectPage(page, event) {
        //console.log("toggleSelectPage", page, event.target.checked);

        if (event.target.checked) {
            this.selectPage(page);
        }
        else {
            this.unselectPage(page);
        }
    }
    unselectPage(page) {
        //console.log("unselectPage", page);

        let selected_pages = this.state.selected_pages.filter((_page) => {
            return _page.page.id !== page.page.id;
        });

        this.setState({
            selected_pages: selected_pages
        }, () => {
            console.log("this.state.selected_pages", this.state.selected_pages);
        });
    }
    selectPage(page) {
        let selected_pages = [...this.state.selected_pages];

        this.setState({
            selected_pages: selected_pages.concat([page])
        }, () => {
            console.log("this.state.selected_pages", this.state.selected_pages);
        });
    }
    // MENU STRUCTURE
    setMenuItems(newState, clearSelectedPages) {
        console.log('setMenuItems', newState);

        this.setState({
            menu_items: newState
        }, () => {
            if (clearSelectedPages) {
                this.clearSelectedPages();
            }
        });
    }
    removeFromMenu = (nodeId) => {
        const filterItems = (items) => {
            return items.filter(item => {
                if (item.id === nodeId) {
                    return false;
                }
                if (item.children && item.children.length) {
                    item.children = filterItems(item.children);
                }
                return true;
            });
        };

        this.setMenuItems({
            ...this.state.menu_items,
            [this.state.working_category]: filterItems([...this.workingCategoryDirectoryMenus()])
        });
    };
    updateMenuItem = (nodeId, key, value) => {

        console.log('updateMenuItem', nodeId, key, value);

        // const { menu_items } = this.state;

        let menu_items = [...this.workingCategoryDirectoryMenus()];

        const updateNode = (nodes) =>
            nodes.map((node) => {
                if (node.id === nodeId) {
                    return { ...node, [key]: value };
                } else if (node.children) {
                    return { ...node, children: updateNode(node.children) };
                }
                return node;
            });
        this.setState({
            menu_items: {
                ...this.state.menu_items,
                [this.state.working_category]: updateNode(menu_items)
            }
        });
    };
    handleMenuChange = (newTreeData) => {
        console.log('handleMenuChange', newTreeData)

        this.setState({
            menu_items: {
                ...this.state.menu_items,
                [this.state.working_category]: newTreeData
            }
        });
    };
    defaultMenuItems() {
        let menu_items = _.find(this.props.data.active_site.settings, (_setting) => { return _setting.key == 'menu_items'; });

        // console.log('menu_items', menu_items)

        if (!_.isEmpty(menu_items)) {
            return JSON.parse(menu_items.value);
        }

        return [];
    }
    toggleNodeExpansion = (nodeId) => {

        this.setState(prevState => ({
            menu_items: {
                ...this.state.menu_items,
                [this.state.working_category]: this.updateNodeExpansion(prevState.menu_items[this.state.working_category], nodeId)
            }
        }));
    };
    updateNodeExpansion = (nodes, nodeId) => {
        return nodes.map(node => {
            if (node.id === nodeId) {
                return { ...node, isExpanded: !node.isExpanded };
            }
            if (node.children) {
                return { ...node, children: this.updateNodeExpansion(node.children, nodeId) };
            }
            return node;
        });
    };
    expansionContentHandler = (node, treeIndex) => {
        return (
            <div className="card border-0 rounded-0 mt-2">
                <div className="card-body p-0">

                    <div className="form-group row">

                        <div className={`col-12 ${(node.type === 'custom_url' || node.type === 'custom') ? 'col-lg-6' : ''}`}>
                            <InputText
                                // name={`menu_items[${treeIndex}][navigation_label]`}
                                label="Navigation title"
                                defaultValue={node.navigation_label}
                                onChange={(event) => this.updateMenuItem(node.id, 'navigation_label', event.target.value)}
                            />
                        </div>


                        {
                            (node.type === 'custom_url' || node.type === 'custom') &&
                            <div className={`col-12 col-lg-6`}>
                                <InputText
                                    // name={`menu_items[${treeIndex}][url]`}
                                    label="URL"
                                    defaultValue={node.url}
                                    onChange={(event) => this.updateMenuItem(node.id, 'url', event.target.value)}
                                    disabled={node.type === "page"}
                                />
                            </div>
                        }


                    </div>
                    <div className="form-group row">
                        <div className="col-12 col-lg-6">
                            <InputText
                                // name={`menu_items[${treeIndex}][css_id]`}
                                label="CSS ID (optional)"
                                defaultValue={node.css_id}
                                onChange={(event) => this.updateMenuItem(node.id, 'css_id', event.target.value)}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <InputText
                                // name={`menu_items[${treeIndex}][css_class]`}
                                label="CSS Classes (optional)"
                                defaultValue={node.css_class}
                                onChange={(event) => this.updateMenuItem(node.id, 'css_class', event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className={`col-12 ${(node.type === 'custom_url' || node.type === 'custom') ? 'col-lg-6' : ''}`}>
                            <InputText
                                // name={`menu_items[${treeIndex}][icon]`}
                                label="Icon"
                                defaultValue={node.icon}
                                onChange={(event) => this.updateMenuItem(node.id, 'icon', event.target.value)}
                            />
                        </div>

                        {
                            (node.type === 'custom_url' || node.type === 'custom') &&
                            <div className="col-12 col-lg-6">
                                <TextArea
                                    // name={`menu_items[${treeIndex}][data_attributes]`}
                                    label="Data attributes (comma separated)"
                                    defaultValue={node.data_attributes}
                                    rows={2}
                                    onChange={(event) => this.updateMenuItem(node.id, 'data_attributes', event.target.value)}
                                />
                            </div>
                        }

                    </div>

                    {
                        (node.type === 'custom_url' || node.type === 'custom') &&
                        <div className="form-group row">
                            <div className="col-12">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        name={`menu_items[${treeIndex}][open_in_new_tab]`}
                                        value="1"
                                        className="custom-control-input"
                                        id={`openInNewTab_${treeIndex}`}
                                        defaultChecked={node.open_in_new_tab}
                                        onChange={(event) => this.updateMenuItem(node.id, 'open_in_new_tab', event.target.checked)}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={`openInNewTab_${treeIndex}`}
                                    >
                                        Open in new tab
                                    </label>
                                </div>
                            </div>
                        </div>
                    }


                    <div className="form-group row">
                        <div className="col-12">
                            <div className="remove-menu-item-btn">
                                <button
                                    className="btn btn-primary btn-danger"
                                    type="submit"
                                    onClick={() => this.removeFromMenu(node.id)}>
                                    Remove Item
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    workingCategoryDirectoryMenus = () => {
        let working_directory = this.state.menu_items[this.state.working_category];

        if (_.isEmpty(working_directory)) {
            working_directory = [];
        }
        return working_directory;
    }
    changeWorkingCategoryDirectory = (category) => {
        this.setState({
            working_category: category
        })
    }

    parentSelectionMenuHandler = () => {

        let menu_items = [...this.workingCategoryDirectoryMenus()];
        let selection_menu = [{ value: -1, text: 'None' }];

        const addItemToMenu = (item, depth = 0) => {
            const prefix = '--'.repeat(depth);
            selection_menu.push({
                value: item.id,
                text: `${prefix}${item.navigation_label}`
            });

            if (item.children && item.children.length > 0) {
                item.children.forEach(child => addItemToMenu(child, depth + 1));
            }
        };

        menu_items.forEach(item => addItemToMenu(item));

        return selection_menu;
    }

    customLinkTypesHandler = () => {
        let custom_link_types_selection = [];

        const custom_link_types_list = _.orderBy(custom_link_types);

        custom_link_types_list.forEach(item => custom_link_types_selection.push({ value: item, text: Helpers.replaceDashToProperCase(item) }));

        return custom_link_types_selection;
    }
    changeCustomLinkType = (type) => {
        this.setState({
            custom_type: type
        })
    }
    render() {
        const { active_site, admin_site, csrf_token, env, pages, redirect_to, routes } = this.props.data;
        const { custom_type } = this.state;
        const router = Router(routes);
        const menuItems = this.workingCategoryDirectoryMenus();

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2>Top Bar Menu Editor</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className="card border mb-3">
                            <div className="card-header">
                                <h4 className="mb-0">Pages</h4>
                            </div>
                            <div className="card-body overflow-y-scroll" style={{ maxHeight: '300px' }}>
                                <ul className="list-unstyled">
                                    {
                                        pages.data.map((page, key) => {

                                            return (
                                                <li key={key}>
                                                    <div className="custom-control custom-checkbox">
                                                        <div className="row">
                                                            <div className="col-12 col-lg-8">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={"page_" + key}
                                                                    onChange={(event) => { this.toggleSelectPage(page, event) }}
                                                                    checked={_.findIndex(this.state.selected_pages, (_page) => { return _page.page.id == page.page.id; }) > -1}
                                                                />
                                                                <label className="custom-control-label" htmlFor={"page_" + key}>
                                                                    {page.page.title}
                                                                </label>
                                                            </div>
                                                            <div className="col-4 text-right text-truncate text-nowrap">
                                                                <i className="font-weight-normal text-muted small text-capitalize">{page.page.status}</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="card-footer border-top-0">
                                <div className="float-right">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={_.isEmpty(this.state.selected_pages)}
                                        onClick={this.addPagesToMenu}>
                                        Add to menu
                                    </button>
                                </div>
                            </div>
                        </div>
                        <form id="custom_links" onSubmit={this.addCustomLinkToMenu}>
                            <div className="card border">
                                <div className="card-header">
                                    <h4 className="mb-0">Custom Links</h4>
                                </div>
                                <div className="card-body overflow-y-scroll" style={{ minHeight: '500px' }}>


                                    <div className="form-group form-row row">
                                        <div className="col-12 col-lg-6">
                                            <Select
                                                name="type"
                                                label="Type"
                                                options={this.customLinkTypesHandler()}
                                                onChange={(type) => this.changeCustomLinkType(type)}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <InputText name="navigation_label" label="Navigation title" required />
                                        </div>
                                    </div>

                                    <div className="form-group form-row row">

                                        {
                                            custom_type === 'custom_url' &&
                                            <div className="col-12 col-lg-6">
                                                <InputText
                                                    name="url"
                                                    label="URL"
                                                />
                                            </div>
                                        }

                                        <div className={`col-12 ${custom_type === 'custom_url' ? 'col-lg-6' : ''}`}>
                                            <InputText name="icon" label="Icon" />
                                        </div>
                                    </div>



                                    <div className="form-group form-row row">
                                        <div className="col-12 col-lg-6">
                                            <InputText name="css_id" label="CSS ID (optional)" />
                                        </div>
                                        <div className="col-12  col-lg-6">
                                            <InputText name="css_class" label="CSS Classes (optional)" />
                                        </div>
                                    </div>

                                    {
                                        custom_type === 'custom_url' &&
                                        <div className="form-group form-row row">
                                            <div className="col-12">
                                                <TextArea
                                                    name="data_attributes"
                                                    label="Data attributes (comma separated)"
                                                />
                                            </div>
                                        </div>
                                    }



                                    <div className="form-group row">
                                        <div className={`col-12  ${custom_type === 'custom_url' ? 'col-lg-6' : ''}`}>
                                            <Select
                                                name="menu_parent"
                                                label="Parent Menu"
                                                options={this.parentSelectionMenuHandler()}
                                            />
                                        </div>

                                        {
                                            custom_type === 'custom_url' &&
                                            <div className="col-12  col-lg-6">
                                                <div className="custom-control custom-checkbox mt-lg-4">
                                                    <input type="checkbox" name="open_in_new_tab" value="1" className="custom-control-input" id="openInNewTab" />
                                                    <label className="custom-control-label" htmlFor="openInNewTab">Open in new tab</label>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className="card-footer border-top-0">
                                    <div className="float-right">
                                        <button className="btn btn-primary" type="submit">
                                            Add to menu
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-lg-7">
                        <div className="card border mb-5" style={{ minHeight: '100%' }}>
                            <div className="card-header">

                                <div className="row">
                                    {
                                        <div className="col-12 col-lg-4">
                                            <Select
                                                name="menu_category"
                                                options={this.props.organizationMenuHandler()}
                                                defaultValue={this.state.working_category}
                                                onChange={(category) => this.changeWorkingCategoryDirectory(category)}
                                            />
                                        </div>
                                    }
                                    <div className="col-12 col-lg-8">
                                        <div className="justify-content-between align-items-center">
                                            <h4 className="mb-0">Menu Structure</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="card-body">
                                {
                                    _.isEmpty(this.state.menu_items) ?
                                        <div className="mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <div>Add menu items from the column on the left.</div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <div>Drag each item into the order you prefer. Click the arrow to the right of each item to reveal additional configuration options.</div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div style={{}}>

                                                        <SortableTree
                                                            isVirtualized={false}
                                                            treeData={menuItems}
                                                            rowHeight={({ node }) => {
                                                                return node.isExpanded ? (node.children && node.children.length > 0 ? 450 : 450) : 62;
                                                            }}
                                                            onChange={this.handleMenuChange}
                                                            generateNodeProps={({ node, treeIndex, path }) => {

                                                                // Check if node is a child (has a parent in the path)
                                                                const isChild = path.length > 1;

                                                                return ({
                                                                    title: (
                                                                        <div>
                                                                            <div className="row cursor-pointer" onClick={() => this.toggleNodeExpansion(node.id)}>

                                                                                <div className="col-6 col-lg-8">
                                                                                    <div className="media">
                                                                                        <div className="media-body align-self-center">
                                                                                            <div className="font-weight-bold">
                                                                                                {node.navigation_label} {isChild && <i className="font-weight-normal text-muted small">sub-menu</i>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-6 col-lg-4 text-right">
                                                                                    <span className="font-weight-normal text-muted small mr-2" title="Page">
                                                                                        {/* <i className="fa-regular fa-file"></i> */}
                                                                                        {node.type}
                                                                                    </span>
                                                                                    <i className={`fa fa-angle-${!node.isExpanded ? "down" : "up"}`}></i>
                                                                                </div>
                                                                            </div>

                                                                            {node?.isExpanded && this.expansionContentHandler(node, treeIndex)}

                                                                        </div>
                                                                    ),
                                                                })
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <form action={router.url('settings.update', { domain: admin_site.domain })} method="POST">
                                    <InputHidden name="_token" defaultValue={csrf_token} />
                                    <InputHidden name="site_id" defaultValue={active_site.site.id} />
                                    <InputHidden name="redirect_to" defaultValue={redirect_to} />

                                    <div className="d-none">
                                        <textarea name="menu_items" value={JSON.stringify(this.state.menu_items)} readOnly={true} />
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={_.isEmpty(this.state.menu_items)}>
                                        Save changes
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MenuEditor;
