import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { API, Helpers, Router, Translator } from '../Helpers';
import ErrorBoundary from "../../withErrorBoundary/withErrorBoundary";
import { InputHidden } from "../Core";
import { v4 as uuidv4 } from 'uuid'; // Import UUID for unique IDs

/**
 * Typography Manager Component
 */
export default class App extends Component {
    constructor(props) {
        super(props);

        const custom_fonts_string = this.findSiteSetting('custom_fonts');
        const custom_fonts = custom_fonts_string ? JSON.parse(custom_fonts_string) : [];

        const customSelectors = custom_fonts && Array.isArray(custom_fonts.value)
            ? custom_fonts.value.map(entry => ({
                id: uuidv4(), // Assign a unique ID
                selector: entry.element,
                fontFamily: entry.font_family || '',
            }))
            : [
                { id: uuidv4(), selector: 'body', fontFamily: this.findSiteSetting('body_font_family') || '' },
                { id: uuidv4(), selector: 'h1', fontFamily: this.findSiteSetting('h1_font_family') || '' },
                { id: uuidv4(), selector: 'h2', fontFamily: this.findSiteSetting('h2_font_family') || '' },
                { id: uuidv4(), selector: 'h3', fontFamily: this.findSiteSetting('h3_font_family') || '' },
                { id: uuidv4(), selector: 'h4', fontFamily: this.findSiteSetting('h4_font_family') || '' },
                { id: uuidv4(), selector: 'h5', fontFamily: this.findSiteSetting('h5_font_family') || '' },
                { id: uuidv4(), selector: 'h6', fontFamily: this.findSiteSetting('h6_font_family') || '' },
            ];

        this.state = {
            fonts: [],
            customSelectors,
            errors: {}, // To track validation errors by id
            loading: true,
            error: null,
            loadedFonts: new Set(),
            fontLoading: null,
        };

        this.findSiteSetting = this.findSiteSetting.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Fetch Google Fonts
     */
    async componentDidMount() {
        try {
            const API_KEY = process.env.MIX_GOOGLE_FONT_API_KEY;
            const response = await fetch(
                `https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`
            );

            if (!response.ok) {
                throw new Error('Failed to fetch Google Fonts');
            }

            const data = await response.json();
            const fonts = data.items.map((font) => ({
                value: font.family,
                label: font.family,
            }));

            this.setState({ fonts, loading: false }, () => {
                // After setting fonts, load initial typography fonts
                const { customSelectors } = this.state;
                customSelectors.forEach((selectorObj) => {
                    if (selectorObj.fontFamily) {
                        this.loadFont(selectorObj.fontFamily);
                    }
                });
            });
        } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    }

    /**
     * Dynamically Load Font
     */
    async loadFont(fontFamily) {
        if (!fontFamily || this.state.loadedFonts.has(fontFamily)) return Promise.resolve();

        return new Promise((resolve) => {
            const fontUrl = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
                / /g,
                '+'
            )}:wght@400&display=swap`;

            if (!document.querySelector(`link[href="${fontUrl}"]`)) {
                const link = document.createElement('link');
                link.href = fontUrl;
                link.rel = 'stylesheet';

                link.onload = () => {
                    document.fonts.ready
                        .then(() => {
                            this.setState((prevState) => ({
                                loadedFonts: new Set(prevState.loadedFonts).add(fontFamily),
                            }));
                            resolve();
                        })
                        .catch(() => {
                            console.warn(`Failed to verify font load: ${fontFamily}`);
                            resolve();
                        });
                };

                document.head.appendChild(link);
            } else {
                document.fonts.ready.then(resolve);
            }
        });
    }

    /**
     * Handle Dropdown Changes
     * @param {string} id - Unique identifier for the selector
     * @param {object} selectedOption - Selected font option
     */
    async handleChange(id, selectedOption) {
        const fontFamily = selectedOption?.value || '';

        // Show loading indicator
        this.setState({ fontLoading: id });

        // Load the font before applying it
        await this.loadFont(fontFamily);

        // Apply the font after it's loaded
        this.setState((prevState) => ({
            fontLoading: null,
            customSelectors: prevState.customSelectors.map((selectorObj) =>
                selectorObj.id === id ? { ...selectorObj, fontFamily } : selectorObj
            ),
        }));
    }

    /**
     * Handle Custom Selector Changes
     * @param {string} id - Unique identifier for the selector
     * @param {string} field - Field to update ('selector' or 'fontFamily')
     * @param {string} value - New value for the field
     */
    handleCustomSelectorChange = (id, field, value) => {
        if (field === 'selector') {
            const trimmedValue = value.trim();
            let error = '';

            // Basic validation: must start with '.' or '#' or be a standard element
            if (trimmedValue && !/^(\.[A-Za-z0-9_-]+|#[A-Za-z0-9_-]+|body|h[1-6])$/.test(trimmedValue)) {
                error = "Invalid selector. Must start with '.', '#', or be a standard element like 'body', 'h1', etc.";
            }

            // Prevent duplicate selectors
            const isDuplicate = this.state.customSelectors.some(
                (selectorObj) => selectorObj.selector === trimmedValue && selectorObj.id !== id
            );
            if (isDuplicate) {
                error = "Duplicate selector detected. Please enter a unique selector.";
            }

            // Prevent setting selector to empty
            if (field === 'selector' && !trimmedValue) {
                error = "Selector cannot be empty.";
            }

            this.setState((prevState) => ({
                customSelectors: prevState.customSelectors.map((selectorObj) =>
                    selectorObj.id === id ? { ...selectorObj, [field]: value } : selectorObj
                ),
                errors: {
                    ...prevState.errors,
                    [id]: error, // Assign error message to the specific selector's id
                },
            }));
        } else {
            // Handle other fields if necessary
            this.setState((prevState) => ({
                customSelectors: prevState.customSelectors.map((selectorObj) =>
                    selectorObj.id === id ? { ...selectorObj, [field]: value } : selectorObj
                ),
            }));
        }
    };

    /**
     * Handle Blur Event for Selector Input
     * @param {object} selectorObj - The selector object that lost focus
     */
    handleBlur = (selectorObj) => {
        const { id, selector } = selectorObj;
        const trimmedValue = selector.trim();
        let error = '';

        if (!trimmedValue) {
            error = "Selector cannot be empty.";
        } else if (!/^(\.[A-Za-z0-9_-]+|#[A-Za-z0-9_-]+|body|h[1-6])$/.test(trimmedValue)) {
            error = "Invalid selector. Must start with '.', '#', or be a standard element like 'body', 'h1', etc.";
        } else {
            // Check for duplicates
            const duplicates = this.state.customSelectors.filter(
                (s) => s.selector === trimmedValue
            );
            if (duplicates.length > 1) {
                error = "Duplicate selector detected. Please enter a unique selector.";
            }
        }

        this.setState((prevState) => ({
            errors: {
                ...prevState.errors,
                [id]: error,
            },
        }));
    };

    /**
     * Handle Custom Font Change
     * @param {string} id - Unique identifier for the selector
     * @param {object} selectedOption - Selected font option
     */
    handleCustomFontChange = async (id, selectedOption) => {
        const fontFamily = selectedOption?.value || '';

        // Show loading indicator for this selector
        this.setState({ fontLoading: id });

        // Load the font before applying it
        await this.loadFont(fontFamily);

        // Update the selector's font family
        this.setState((prevState) => ({
            fontLoading: null,
            customSelectors: prevState.customSelectors.map((selectorObj) =>
                selectorObj.id === id ? { ...selectorObj, fontFamily } : selectorObj
            ),
        }));
    };

    /**
     * Add a New Custom Selector
     */
    addCustomSelector = () => {
        // Check if there's any selector with empty 'selector' value
        const hasEmptySelector = this.state.customSelectors.some(
            (selectorObj) => !selectorObj.selector.trim()
        );

        if (hasEmptySelector) {
            alert("Please fill in the existing empty selector before adding a new one.");
            return;
        }

        this.setState((prevState) => ({
            customSelectors: [
                ...prevState.customSelectors,
                {
                    id: uuidv4(),
                    selector: '', // Empty selector to be filled by the user
                    fontFamily: '',
                },
            ],
        }));
    };

    /**
     * Remove a Custom Selector
     * @param {string} id - Unique identifier for the selector to remove
     */
    removeCustomSelector = (id) => {
        this.setState((prevState) => ({
            customSelectors: prevState.customSelectors.filter((selectorObj) => selectorObj.id !== id),
            errors: {
                ...prevState.errors,
                [id]: undefined, // Remove any existing error for this selector
            },
        }));
    };

    /**
     * Find Site Setting
     */
    findSiteSetting(key) {
        let setting = _.find(this.props.data.active_site.settings, (setting) => { return setting.key === key; });

        if (!_.isEmpty(setting)) {
            return setting.value;
        }
    }

    /**
     * Check if a selector is a standard one
     */
    isStandardSelector = (selector) => {
        const standardSelectors = ['body', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
        return standardSelectors.includes(selector);
    };

    /**
     * Render Custom Selectors
     */
    renderCustomSelectors() {
        const { customSelectors, fonts, fontLoading, errors } = this.state;

        return customSelectors.map((selectorObj) => (
            <div key={selectorObj.id} className="mb-3 p-2 border rounded">
                <div className="form-row align-items-end">
                    <div className="form-group col-md-5">
                        <label>Selector</label>
                        <input
                            type="text"
                            className={`form-control ${errors[selectorObj.id] ? 'is-invalid' : ''}`}
                            value={selectorObj.selector}
                            onChange={(e) => this.handleCustomSelectorChange(selectorObj.id, 'selector', e.target.value)}
                            onBlur={() => this.handleBlur(selectorObj)}
                            placeholder=".custom-class or #custom-id or element selector (e.g., h1)"
                            disabled={this.isStandardSelector(selectorObj.selector)}
                        />
                        {errors[selectorObj.id] && (
                            <div className="invalid-feedback">
                                {errors[selectorObj.id]}
                            </div>
                        )}
                    </div>
                    <div className="form-group col-md-5">
                        <label>Font Family {fontLoading === selectorObj.id && '(Loading...)'}</label>
                        <Select
                            name={`custom_fonts[value][${this.state.customSelectors.indexOf(selectorObj)}][font_family]`}
                            value={fonts.find((option) => option.value === selectorObj.fontFamily) || null}
                            onChange={(selectedOption) => this.handleCustomFontChange(selectorObj.id, selectedOption)}
                            options={fonts}
                            placeholder="Select a font"
                            isClearable
                            isSearchable
                        />
                    </div>
                    <div className="form-group col-md-2 text-right">
                        {!this.isStandardSelector(selectorObj.selector) && (
                            <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => this.removeCustomSelector(selectorObj.id)}
                            >
                                Remove
                            </button>
                        )}
                    </div>
                </div>
            </div>
        ));
    }

    /**
     * Render Custom Selectors Section
     */
    renderCustomSelectorsSection() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Typography Selectors</h5>
                </div>
                {this.renderCustomSelectors()}
            </>
        );
    }

    /**
     * Render Live Preview Panel
     */
    renderPreviewPanel() {
        const { customSelectors } = this.state;

        // Filter out selectors that have errors
        const validSelectors = customSelectors.filter(selectorObj => !this.state.errors[selectorObj.id]);

        // Generate styles for all selectors, skipping any with empty 'selector'
        const customStyles = validSelectors.reduce((styles, selectorObj) => {
            if (selectorObj.fontFamily && selectorObj.selector.trim()) {
                // Special handling for 'body' selector so only the preview content is affected
                if (selectorObj.selector === 'body') {
                    styles += `.body-content { font-family: '${selectorObj.fontFamily}', sans-serif; }\n`;
                } else {
                    styles += `${selectorObj.selector} { font-family: '${selectorObj.fontFamily}', sans-serif; }\n`;
                }
            }
            return styles;
        }, '');

        return (
            <div className="preview-panel p-4 border rounded shadow-sm bg-light" style={{ maxHeight: '80vh', overflowY: 'auto', position: 'sticky', top: '80px' }}>
                <h6 className="text-muted mb-3" style={{ fontFamily: 'inherit' }}>Live Preview</h6>

                {/* Inject custom styles */}
                <style>{customStyles}</style>

                {/* Simulated Body Content */}
                <div className="simulated-body">
                    {/* Render selectors dynamically */}
                    {validSelectors.map((selectorObj, index) => {
                        const selector = selectorObj.selector?.trim();

                        // Skip rendering if selector is empty
                        if (!selector) return null;

                        // Determine the type of selector
                        const isClass = selector.startsWith('.');
                        const isId = selector.startsWith('#');
                        const isElement = !isClass && !isId;

                        // Extract class or ID name
                        const classOrIdName = isClass || isId ? selector.slice(1) : '';

                        // Determine sample text
                        let sampleText = '';
                        if (isElement) {
                            sampleText = `${selector.toUpperCase()} Example`;
                        } else if (isClass) {
                            sampleText = `Custom Class: ${classOrIdName}`;
                        } else if (isId) {
                            sampleText = `Custom ID: ${classOrIdName}`;
                        }

                        // Render the appropriate element
                        if (isElement && selector !== 'body') {
                            // For standard element selectors (excluding 'body')
                            return React.createElement(
                                selector,
                                { key: `${selector}-${index}` },
                                sampleText
                            );
                        } else if (isElement && selector === 'body') {
                            // For 'body' selector, apply styles to the container div
                            return (
                                <div key={`body-${index}`} className="body-content">
                                    <p>{sampleText}</p>
                                </div>
                            );
                        } else if (isClass) {
                            return (
                                <div key={`class-${classOrIdName}-${index}`} className={classOrIdName} style={{ marginTop: '10px' }}>
                                    <p>{sampleText}</p>
                                </div>
                            );
                        } else if (isId) {
                            return (
                                <div key={`id-${classOrIdName}-${index}`} id={classOrIdName} style={{ marginTop: '10px' }}>
                                    <p>{sampleText}</p>
                                </div>
                            );
                        } else {
                            return null; // For any unexpected selector types
                        }
                    })}
                </div>
            </div>
        );
    }

    render() {
        const { active_site, admin_site, csrf_token, env, redirect_to, request, routes } = this.props.data;

        const router = Router(routes);
        const { loading, error } = this.state;

        if (loading) {
            return (
                <div className="text-center my-5">
                    <div className="spinner-border text-primary"></div>
                    <p>Loading fonts...</p>
                </div>
            );
        }

        if (error) {
            return (
                <div className="alert alert-danger text-center">
                    Error: {error}
                </div>
            );
        }

        // Check if there are any validation errors
        const hasErrors = Object.values(this.state.errors).some((errorMsg) => errorMsg);

        return (
            <div className="container-fluid mb-4">
                <h2 className="mb-4">Typography Settings</h2>
                <form action={router.url('settings.update', { domain: admin_site.domain })} method="POST">
                    <InputHidden name="_token" defaultValue={csrf_token} />
                    <InputHidden name="site_id" defaultValue={active_site.site.id} />
                    <InputHidden name="redirect_to" defaultValue={redirect_to} />

                    <div className="row">
                        {/* Font Selectors */}
                        <div className="col-md-6">
                            <div className="card p-4 border shadow-sm">
                                <h4 className="mb-3">Font Settings</h4>
                                {/* Typography Selectors */}
                                {this.renderCustomSelectorsSection()}

                                {/* Serialize All Selectors as custom_fonts */}
                                {this.state.customSelectors.map((selectorObj, index) => (
                                    <React.Fragment key={selectorObj.id}>
                                        <InputHidden
                                            name={`custom_fonts[value][${index}][element]`}
                                            defaultValue={selectorObj.selector}
                                        />
                                        <InputHidden
                                            name={`custom_fonts[value][${index}][font_family]`}
                                            defaultValue={selectorObj.fontFamily}
                                        />
                                    </React.Fragment>
                                ))}

                                {/* Add Selector Button */}
                                <div className="text-right mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                        onClick={this.addCustomSelector}
                                    >
                                        Add Selector
                                    </button>
                                </div>

                                {/* Save Button */}
                                <div className="text-right mt-3">
                                    <button type="submit" className="btn btn-sm btn-primary" disabled={hasErrors}>
                                        Save Changes
                                    </button>
                                    {hasErrors && (
                                        <small className="text-danger ml-2">
                                            Please resolve validation errors before saving.
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Live Preview */}
                        <div className="col-md-6">
                            {this.renderPreviewPanel()}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

/**
 * Mount Component
 */
if (document.getElementById('typographyEditor')) {
    const element = document.getElementById('typographyEditor');
    const data = Object.assign({}, element.dataset);

    ReactDOM.render(<ErrorBoundary><App data={JSON.parse(data.data)} /></ErrorBoundary>, element);
}