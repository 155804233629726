import { API, Helpers, Router } from '../Helpers';
// import { Checkbox, Radio, RadioGroup } from 'react-icheck';
import { InputHidden, InputText, TextArea } from '../Core';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactSortable } from "react-sortablejs";
import Vapor from 'laravel-vapor';
import MenuEditor from './MenuEditor';
import HeroBannerEditor from './HeroBannerEditor';
import { Notification } from '../Shared';

const menu_types = ['home', 'search_results_page', 'product_details_page', 'checkout_page', 'confirmation_page', 'booking_details_page', 'booking_payment_page'];

export default class Edit extends Component {
    constructor(props) {
        super(props);

        // Get the tab from the URL or default to 'header'
        const urlParams = new URLSearchParams(window.location.search);
        const initialTab = urlParams.get('tab') || 'header';

        this.state = {
            activeTab: initialTab, // Set activeTab based on URL parameter
            header_layout: this.findSiteSetting('header_layout'),
        };

        this.findSiteSetting = this.findSiteSetting.bind(this);
        this.setHeaderLayout = this.setHeaderLayout.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    findSiteSetting(key) {
        let setting = _.find(this.props.data.active_site.settings, (setting) => { return setting.key == key; });

        if (!_.isEmpty(setting)) {
            return setting.value;
        }
    }

    setHeaderLayout(event) {
        console.log("setHeaderLayout");

        //console.log(index);
        //console.log(event.target);
        console.log(event.target.value);
        console.log(event.target.name);

        this.setState({
            header_layout: event.target.value
        });
    }

    handleTabChange(tabId) {
        // Update the URL when the tab changes
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('tab', tabId);
        window.history.pushState(null, '', `${window.location.pathname}?${urlParams.toString()}`);

        // Update the state
        this.setState({ activeTab: tabId });
    }

    organizationMenuHandler = (exclude = []) => {
        let selection_menu_types = [{ value: 'default', text: 'Default' }];

        const menu_types_list = _.orderBy(menu_types);

        menu_types_list.forEach(item => selection_menu_types.push({ value: item, text: Helpers.replaceDashToProperCase(item) }));


        if(!_.isEmpty(exclude)){
            _.remove(selection_menu_types, item => exclude.includes(item.value));
        }


        return selection_menu_types;
    }

    render() {

        const { activeTab, header_layout } = this.state;
        const { active_site, admin_site, csrf_token, env, pages, redirect_to, routes, notification } = this.props.data;
        const router = Router(routes);

        console.log('window.location', window.location.href)

        return (
            <div className="container-fluid mb-3">
                <div className="row mb-5">
                    <div className="col-12">
                        <div className="mb-4">

                            {/* Notifications */}
                            {
                                !_.isEmpty(notification) &&
                                <Notification
                                    notification={notification} />
                            }

                            <ul className="nav nav-pills" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link px-4 py-3 waves-light ${activeTab === 'header' ? 'active' : ''}`}
                                        data-toggle="tab"
                                        href="#header"
                                        role="tab"
                                        onClick={() => this.handleTabChange('header')}
                                    >
                                        Header
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link px-4 py-3 waves-light ${activeTab === 'menu' ? 'active' : ''}`}
                                        data-toggle="tab"
                                        href="#menu"
                                        role="tab"
                                        onClick={() => this.handleTabChange('menu')}
                                    >
                                        Top Bar Menu Editor
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link px-4 py-3 waves-light ${activeTab === 'banner' ? 'active' : ''}`}
                                        data-toggle="tab"
                                        href="#banner"
                                        role="tab"
                                        onClick={() => this.handleTabChange('banner')}
                                    >
                                        Hero Banner
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content px-0">
                                {/* Header */}
                                <div className={`tab-pane fade ${activeTab === 'header' ? 'show active' : ''}`} id="header" role="tabpanel">
                                    {
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h2>Header</h2>
                                                </div>
                                            </div>

                                            <hr />

                                            <div className="row">
                                                <div className="col-12">
                                                    <form action={router.url('settings.update', { domain: admin_site.domain })} method="POST">
                                                        <InputHidden name="_token" defaultValue={csrf_token} />
                                                        <InputHidden name="site_id" defaultValue={active_site.site.id} />
                                                        {/* <InputHidden name="redirect_to" defaultValue={redirect_to} /> */}
                                                        <InputHidden name="redirect_to" defaultValue={window.location.href} />
                                                        <input type="hidden" name="header_layout" value={header_layout} readOnly={true} />

                                                        <div className="card border mb-5">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <h4>Choose a layout to use for the header</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12 col-lg-6">

                                                                        <div className="row mb-3">
                                                                            <div className="col-12 mb-3">
                                                                                <div className="card border h-100">
                                                                                    <div className="card-body">
                                                                                        <div className="custom-control custom-radio mb-3">
                                                                                            <input
                                                                                                name="header_layout"
                                                                                                type="radio"
                                                                                                value="1"
                                                                                                className="custom-control-input"
                                                                                                id="headerLayout1"
                                                                                                onChange={(event) => { this.setHeaderLayout(event) }}
                                                                                                checked={header_layout == 1}
                                                                                            />
                                                                                            <label className="custom-control-label" htmlFor="headerLayout1">Layout 1</label>
                                                                                        </div>

                                                                                        <div>
                                                                                            <img className="img-fluid z-depth-1" src={Vapor.asset('images/layout1.jpg')} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 mb-3">
                                                                                <div className="card border h-100">
                                                                                    <div className="card-body">
                                                                                        <div className="custom-control custom-radio mb-3">
                                                                                            <input
                                                                                                name="header_layout"
                                                                                                type="radio"
                                                                                                value="2"
                                                                                                className="custom-control-input"
                                                                                                id="headerLayout2"
                                                                                                onChange={(event) => { this.setHeaderLayout(event) }}
                                                                                                checked={header_layout == 2}
                                                                                            />
                                                                                            <label className="custom-control-label" htmlFor="headerLayout2">Layout 2</label>
                                                                                        </div>

                                                                                        <div>
                                                                                            <img className="img-fluid z-depth-1" src={Vapor.asset('images/layout2.jpg')} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6">
                                                                        <div className="row">
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <InputHidden name="show_topbar_ribbon" value={0} />
                                                                                    <label className="form-check-label mb-2" htmlFor="show_topbar">
                                                                                        Show the navigation bar
                                                                                    </label>
                                                                                    <div className="switch">
                                                                                        <label>
                                                                                            No
                                                                                            <input
                                                                                                name="show_topbar_ribbon"
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                value={1}
                                                                                                id="show_topbar_ribbon"
                                                                                                defaultChecked={this.findSiteSetting('show_topbar_ribbon') == 1 ? 1 : 0}
                                                                                            />
                                                                                            <span className="lever"></span>
                                                                                            Yes
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <div className="form-group">
                                                                                        <InputHidden name="show_topbar_navmenu" value={0} />
                                                                                        <label className="form-check-label mb-2" htmlFor="show_topbar_navmenu">
                                                                                            Show the topbar navigation menu
                                                                                        </label>
                                                                                        <div className="switch">
                                                                                            <label>
                                                                                                No
                                                                                                <input
                                                                                                    name="show_topbar_navmenu"
                                                                                                    className="form-check-input"
                                                                                                    type="checkbox"
                                                                                                    value={1}
                                                                                                    id="show_topbar_navmenu"
                                                                                                    defaultChecked={this.findSiteSetting('show_topbar_navmenu') == 1 ? 1 : 0}
                                                                                                />
                                                                                                <span className="lever"></span>
                                                                                                Yes
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <InputHidden name="show_topbar_my_account_button" value={0} />
                                                                                    <label className="form-check-label mb-2" htmlFor="show_topbar_my_account_button">
                                                                                        Show the topbar MY ACCOUNT button
                                                                                    </label>
                                                                                    <div className="switch">
                                                                                        <label>
                                                                                            No
                                                                                            <input
                                                                                                name="show_topbar_my_account_button"
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                value={1}
                                                                                                id="show_topbar_my_account_button"
                                                                                                defaultChecked={this.findSiteSetting('show_topbar_my_account_button') == 1 ? 1 : 0}
                                                                                            />
                                                                                            <span className="lever"></span>
                                                                                            Yes
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <InputHidden name="show_topbar_manage_booking_button" value={0} />
                                                                                    <label className="form-check-label mb-2" htmlFor="show_topbar_manage_booking_button">
                                                                                        Show the topbar MANAGE BOOKING button
                                                                                    </label>
                                                                                    <div className="switch">
                                                                                        <label>
                                                                                            No
                                                                                            <input
                                                                                                name="show_topbar_manage_booking_button"
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                value={1}
                                                                                                id="show_topbar_manage_booking_button"
                                                                                                defaultChecked={this.findSiteSetting('show_topbar_manage_booking_button') == 1 ? 1 : 0}
                                                                                            />
                                                                                            <span className="lever"></span>
                                                                                            Yes
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <InputHidden name="show_topbar_phone_number" value={0} />
                                                                                    <label className="form-check-label mb-2" htmlFor="show_topbar_phone_number">
                                                                                        Show the topbar PHONE NUMBER
                                                                                    </label>
                                                                                    <div className="switch">
                                                                                        <label>
                                                                                            No
                                                                                            <input
                                                                                                name="show_topbar_phone_number"
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                value={1}
                                                                                                id="show_topbar_phone_number"
                                                                                                defaultChecked={this.findSiteSetting('show_topbar_phone_number') == 1 ? 1 : 0}
                                                                                            />
                                                                                            <span className="lever"></span>
                                                                                            Yes
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <InputHidden name="show_topbar_language_picker" value={0} />
                                                                                    <label className="form-check-label mb-2" htmlFor="show_topbar_language_picker">
                                                                                        Show the topbar language picker
                                                                                    </label>
                                                                                    <div className="switch">
                                                                                        <label>
                                                                                            No
                                                                                            <input
                                                                                                name="show_topbar_language_picker"
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                value={1}
                                                                                                id="show_topbar_language_picker"
                                                                                                defaultChecked={this.findSiteSetting('show_topbar_language_picker') == 1 ? 1 : 0}
                                                                                            />
                                                                                            <span className="lever"></span>
                                                                                            Yes
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <InputHidden name="keep_topbar_fixed" value={0} />
                                                                                    <label className="form-check-label mb-2" htmlFor="keep_topbar_fixed">
                                                                                        Keep the header fixed while scrolling
                                                                                    </label>
                                                                                    <div className="switch">
                                                                                        <label>
                                                                                            No
                                                                                            <input
                                                                                                name="keep_topbar_fixed"
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                value={1}
                                                                                                id="topbar_fixed_top"
                                                                                                defaultChecked={this.findSiteSetting('keep_topbar_fixed') == 1 ? 1 : 0}
                                                                                            />
                                                                                            <span className="lever"></span>
                                                                                            Yes
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer d-flex justify-content-end">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="submit">
                                                                    Save changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                {/* Menu Editor */}
                                <div className={`tab-pane fade ${activeTab === 'menu' ? 'show active' : ''}`} id="menu" role="tabpanel">
                                    <MenuEditor
                                        data={{ 
                                            ...this.props.data, 
                                            redirect_to: window.location.href 
                                        }}
                                        menu_types={menu_types}
                                        organizationMenuHandler={this.organizationMenuHandler} />
                                </div>
                                {/* Hero Banner */}
                                <div className={`tab-pane fade ${activeTab === 'banner' ? 'show active' : ''}`} id="banner" role="tabpanel">
                                    <HeroBannerEditor
                                        data={{ 
                                            ...this.props.data, 
                                            redirect_to: window.location.href 
                                        }}
                                        menu_types={menu_types}
                                        organizationMenuHandler={this.organizationMenuHandler} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

if (document.getElementById('headerEdit')) {

    const element = document.getElementById('headerEdit');
    const data = Object.assign({}, element.dataset);

    //console.log(data.data);

    ReactDOM.render(<Edit data={JSON.parse(data.data)} />, document.getElementById('headerEdit'));
}
