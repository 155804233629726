import { startCase } from 'lodash';

export default {
    formatCurrency: (value, currency, language) => {
        const formatter = new Intl.NumberFormat(language, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2
        });

        return formatter.format(value);
    },
    formatNoDecimalCurrency: (value, symbol) => {
        if (!_.isEmpty(symbol)) {
            return numeral(value).format(symbol + '0,0');
        }

        return numeral(value).format('$0,0');
    },
    formatHoursMinutesSeconds: (milliseconds) => {
        let number = Number(milliseconds / 1000);

        let h = Math.floor(number / 3600);
        let m = Math.floor(number % 3600 / 60);
        let s = Math.floor(number % 3600 % 60);

        let hours = h > 0 ? h + "h " : "";
        let minutes = m > 0 ? m + "m " : "";
        let seconds = s > 0 ? s + "s" : "";

        return hours + minutes + seconds;
    },
    merge: (a, b) => {
        return Object.assign({}, a, b);
    },
    pluralize: (word, count, inclusive) => {
        if (inclusive) {
            return pluralize(word, _.toNumber(count), inclusive);
        }

        return pluralize(word, _.toNumber(count));
    },
    serializeForm: (form) => {
        var data = {};

        function buildInputObject(arr, val) {
            if (arr.length < 1)
                return val;
            var objkey = arr[0];
            if (objkey.slice(-1) == "]") {
                objkey = objkey.slice(0, -1);
            }
            var result = {};
            if (arr.length == 1) {
                result[objkey] = val;
            } else {
                arr.shift();
                var nestedVal = buildInputObject(arr, val);
                result[objkey] = nestedVal;
            }
            return result;
        }

        $.each($(form).serializeArray(), function () {
            var val = this.value;

            //console.log(val);

            if (!_.isEmpty(val)) {
                var c = this.name.split("[");
                var a = buildInputObject(c, val);
                $.extend(true, data, a);
            }
        });

        return data;
    },
    // OLD
    serializeFormData: (formData) => {
        var object = {};

        formData.forEach((value, key) => {
            // Reflect.has in favor of: object.hasOwnProperty(key)
            if (!Reflect.has(object, key)) {
                object[key] = value;

                return;
            }

            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }

            object[key].push(value);
        });

        return object;
    },
    url: (base, params) => {
        let queryString;

        if (params) {
            queryString = $.param(params);

            return base + "?" + queryString;
        }

        return base;
    },
    toDayDateTimeString: (dateTime) => {
        return moment.utc(new Date(dateTime)).format("MMMM D, YYYY, h:mm A");
    },
    toDateString: (dateTime, separator) => {

        if (!_.isEmpty(separator)) {
            return moment.utc(dateTime).format("YYYY" + separator + "MM" + separator + "DD");
        }

        return moment.utc(dateTime).format("YYYY-MM-DD");
    },
    toFormattedDateString: (dateTime) => {
        return moment.utc(dateTime).format("MMMM D, YYYY");
    },
    toShortDayDateTimeString: (dateTime) => {
        return moment.utc(new Date(dateTime)).format("MMM D YYYY, HH:mm A");
    },
    toIconList: (html, icon_class) => {
        html = _.replace(html, /<ul>/g, '<ul class="list-unstyled mb-0">');
        html = _.replace(html, /<li>/g, '<li><i class="' + icon_class + '"></i>');

        return html;
    },
    toMonthDateString: (dateTime) => {
        return moment.utc(dateTime).format("MMM D");
    },
    toTimeString: (dateTime) => {
        return moment.utc(dateTime).format("h:mm:ss A");
    },
    timeFromNow: (dateTime) => {
        return moment.utc(dateTime).fromNow();
    },
    requestPath: () => {
        return window.location.href.split('?')[0];
    },
    replaceDashToProperCase: (string) => {
        return startCase(string);
    },
    bytesToHuman: (bytes) => {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
        let i = 0;

        while (bytes > 1024 && i < units.length - 1) {
            bytes /= 1024;
            i++;
        }

        return `${bytes.toFixed(2)} ${units[i]}`;
    },
    getFileDetails: (url) => {
        const urlObj = new URL(url);
        const pathname = urlObj.pathname;
        const parts = pathname.split("/");
        const basename = parts.pop();
        const extension = basename.split(".").pop();
        const filename = basename.replace(`.${extension}`, "");
        const path = parts.join("/");

        return { basename, extension, filename, path };
    }
};
