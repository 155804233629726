import React, { Component } from "react";
import { API, Helpers, Router } from "../Helpers";
import { Loader } from "../Core";

class MediaLibraryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            media_list: [],
            selectedImage: null,
            showModal: false,
            uploadProgress: {},
            imageWarnings: [],
            isLoading: false,
            isUploading: false,
            imageListing: {
                listing: {
                    value: []
                }
            },
        };

        this.loadMedia = this.loadMedia.bind(this);
        this.myUploadProgress = this.UploadProgress.bind(this);
        this.updateFileProgress = this.updateFileProgress.bind(this);
        this.saveImageToListing = this.saveImageToListing.bind(this);
    }

    componentDidMount() {
        this.loadMedia();
    }

    loadMedia() {
        const router = Router(this.props.routes);

        let onResponse = {
            success: (response) => {
                const mediaList = Array.isArray(response.data) ? response.data : [];
                this.setState({ media_list: mediaList, isLoading: false });
            },
            error: (error) => console.log("error", error),
        };

        this.setState({
            isLoading: true
        }, () => {
            API.get(onResponse, router.url("media.index", { domain: this.props.admin_site.domain }));
        })
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    // Select an Image
    selectImage = (file) => {
        this.setState({ selectedImage: file });
    };

    // Confirm Image Selection
    confirmSelection = () => {
        if (this.state.selectedImage) {
            this.props.onSelect(this.state.selectedImage); // Send selected image to parent
            this.setState({ showModal: false });
        }
    };

    updateFileProgress(fileId, progress) {

        const updatedForm = {
            ...this.state.imageListing
        }
        const updateFormElement = {
            ...updatedForm['listing']
        }
        updatedForm['listing'] = updateFormElement;
        const foundIndex = updateFormElement.value.findIndex(x => x.id === fileId);
        updateFormElement.value[foundIndex] = {
            ...updateFormElement.value[foundIndex],
            progress: progress
        };
        this.setState({ imageListing: updatedForm })
    }

    saveImageToListing(data) {
        const updatedForm = {
            ...this.state.imageListing
        }
        const updateFormElement = {
            ...updatedForm['listing']
        }
        updatedForm['listing'] = updateFormElement;
        updateFormElement.value = data;
        this.setState({ form: updatedForm });
    }

    UploadProgress(fileId) {
        return function (progress) {
            let percentage = Math.floor((progress.loaded * 100) / progress.total);
            //update file progress
            this.updateFileProgress(fileId, percentage);

        }.bind(this)
    }

    handleUpload = async (event) => {

        this.clearWarnings();

        const file = event.target.files[0];
        if (!file) return;

        if (!this.onSizeValidation(file)) {
            return;
        }

        const router = Router(this.props.routes)

        const formData = new FormData();
        formData.append("image", file);
        const fileId = uniqid();
        const stateData = this.state.imageListing.listing.value;
        stateData.push({
            id: fileId,
            name: file.name,
            size: file.size,
            type: file.type,
            progress: 0
        });

        this.saveImageToListing(stateData);

        const config = {
            headers: {
                'Content-Type': file.type,
            },
            onUploadProgress: this.UploadProgress(fileId)
        }

        this.setState({
            isUploading: true
        }, async () => {
            await axios.post(router.url('media.store', { domain: this.props.admin_site.domain }), formData, config)
                .then((response) => {
                    // console.log('media.store', response)

                    const _mediaList = [...this.state.media_list];

                    _mediaList.unshift({
                        url: response.data.image,
                        ...Helpers.getFileDetails(response.data.image)
                    })

                    this.setState({ media_list: _mediaList, isUploading: false });


                }).catch(err => console.log(err));
        })


    };

    clearWarnings() {
        if (this.state.imageWarnings.length > 0) {
            this.setState({ imageWarnings: [] });
        }
    }

    onSizeValidation(file) {
        const file_size = ((file.size / 1024) / 1024).toFixed(4);

        if (file_size > this.props.upload_size) {
            const warning_data = this.state.imageWarnings;
            warning_data.push(file);
            this.setState({ imageWarnings: warning_data });
            return false;
        }
        return true;
    }

    render() {
        const { media_list, selectedImage, showModal, imageWarnings, isLoading, imageListing, isUploading } = this.state;

        return (
            <>
                <div className={`modal fade ${showModal ? "show d-block" : ""}`} tabIndex="-1" style={{ background: "rgba(0, 0, 0, 0.5)" }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title font-weight-bold">Media Library</h3>

                                <button type="button " onClick={this.closeModal} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>


                            </div>
                            <div className="modal-body" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                {/* Image Upload Button */}
                                <div className="d-flex justify-content-center mt-4 mb-3">
                                    <input
                                        type="file"
                                        className="d-none"
                                        id="image-upload"
                                        accept="image/*"
                                        onChange={this.handleUpload}
                                    />
                                    <label htmlFor="image-upload" className="btn btn-primary">
                                        Select Image to Upload
                                    </label>
                                </div>

                                {
                                    imageWarnings.length > 0 && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            <p>Some files are too large:</p>
                                            <ul>
                                                {imageWarnings.map((file, key) => (
                                                    <li key={key}>
                                                        {file.name} - {file.size} bytes
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                }

                                {
                                    !imageListing.listing.value.every(file => file.progress === 100) &&
                                    <div className="card z-depth-0">
                                        <div className="card-body p-0">
                                            <div className={'list-group list-group-flush'}>
                                                {
                                                    imageListing.listing.value.map((file, key) => (
                                                        <li
                                                            key={key}
                                                            className="list-group-item"
                                                        >
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <div>{file.name} - {file.size + ' bytes'}</div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="progress w-100">
                                                                        <div className="progress-bar" role="progressbar" style={{ width: file.progress + '%' }} aria-valuenow={file.progress} aria-valuemin="0" aria-valuemax="100">{file.progress + '%'}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }


                                {
                                    (isLoading || isUploading) &&
                                    <Loader />
                                }

                                {
                                    !isLoading &&
                                    <div className="row">
                                        {Array.isArray(media_list) && media_list.length > 0 ? (
                                            media_list.map((file) => (
                                                <div key={file.filename} className="col-md-3 col-sm-6 mb-3">
                                                    <div
                                                        className={`card shadow-none media-card ${selectedImage?.filename === file.filename ? "selected" : ""}`}
                                                        onClick={() => this.selectImage(file)}
                                                    >
                                                        <img src={file.url} className="card-img-top" alt={file.filename} />
                                                        {/* <span>{Helpers.bytesToHuman(file.size)}</span> */}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="col-12 text-center">No media available</div>
                                        )}
                                    </div>
                                }



                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.confirmSelection}
                                    disabled={!selectedImage}
                                >
                                    Confirm Selection
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MediaLibraryModal;
