import React, { Component } from "react";

import FroalaEditorComponent from 'react-froala-wysiwyg';

import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';

function EditorComponent(props) {

    let config = {
        placeholderText: 'Edit Your Content Here!',
        documentReady: false,
        heightMin: 400,
        events: {
            'contentChanged': function (e, editor) {
                console.log('test');
            }
        },
        key: process.env.MIX_FROALA_EDITOR_KEY
        // toolbarButtons: {
        //     'moreText': {
        //         'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
        //     },
        //     'moreParagraph': {
        //         'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
        //     },
        //     'moreRich': {
        //         'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
        //     },
        //     'moreMisc': {
        //         'buttons': ['undo', 'redo', 'fullscreen', 'print', 'spellChecker', 'selectAll', 'html', 'help']
        //     }
        // },
        // codeViewKeepActiveButtons: ['selectAll'],
        // pluginsEnabled: ['codeView', 'codeBeautifier', 'align', 'fontSize'],
    };

    return (

        <div className="editor">
            <FroalaEditorComponent tag='textarea' config={config} {...props} />
        </div>
    );

}

export default EditorComponent;