import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Notification extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        const { notification } = this.props;
        
        return (
            <div className={`alert ${notification.type === 'error'?'alert-error':'alert-success'}`} role="alert">
                <div data-cy="system_notification" dangerouslySetInnerHTML={{ __html: notification.message }} />
            </div>
        );
    }
}
