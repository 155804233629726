import React, { Component, createRef } from "react";
import { API, Helpers, Router } from '../Helpers';
import { InputHidden, InputText, Select, TextArea } from '../Core';
import SortableTree from "react-sortable-tree";
import Vapor from 'laravel-vapor';
import EditorComponent from "../Shared/EditorComponent";
import { MediaLibraryModal } from "../Shared";

class HeroBannerEditor extends Component {
    constructor(props) {
        super(props);

        let language_codes = this.props.data.languages

        if (!Array.isArray(this.props.data.languages)) {
            language_codes = Object.values(this.props.data?.languages);
        }

        this.state = {
            translation: {},
            language_codes: language_codes,
            language_code: 'en-us',
            content: this.defaultContentStructure(),
            working_category: 'search_results_page',
            working_category_id: this.props.data.active_site.site.id + '_search_results_page',
            selectedImage: null
        };

        this.mediaLibraryRef = createRef();

        this.findSiteSetting = this.findSiteSetting.bind(this);
        this.selectLanguage = this.selectLanguage.bind(this);
        this.loadLanguageTermTranslations = this.loadLanguageTermTranslations.bind(this);
    }

    componentDidMount() {
        this.loadLanguageTermTranslations();
    }

    defaultContentStructure = () => {
        return {
            title: null,
            subtitle: null,
            body: undefined,
            backgroundImage: null
        }
    }

    // SYSTEM
    findSiteSetting(key) {
        let setting = _.find(this.props.data.active_site.settings, (setting) => { return setting.key == key; });

        if (!_.isEmpty(setting)) {
            return setting.value;
        }
    }
    workingCategoryDirectoryMenus = () => {
        // let working_directory = this.state.menu_items[this.state.working_category];

        // if (_.isEmpty(working_directory)) {
        //     working_directory = [];
        // }
        // return working_directory;
    }
    changeWorkingCategoryDirectory = (category) => {
        this.setState({
            working_category: category,
            working_category_id: this.props.data.active_site.site.id + '_' + category,
            content: this.defaultContentStructure()
        }, () => {
            this.loadLanguageTermTranslations();
        })
    }
    selectLanguage(code) {
        this.setState({
            language_code: code
        }, () => {
            this.loadLanguageTermTranslations();
        })
    }

    loadLanguageTermTranslations = async () => {
        const router = Router(this.props.data.routes);

        let onResponse = {
            success: (response) => {

                const _data = response.data?.data[0] || {}

                let content = {
                    ...!_.isEmpty(_data?.text) ? JSON.parse(_data?.text) : this.defaultContentStructure()
                }

                if (_.isEmpty(content.body)) {
                    content = {
                        ...content,
                        body: '<p></p>'
                    }
                }

                this.setState({
                    translation: _data,
                    content: content
                })

            },
            error: (error) => {

                console.log(error);

            }
        }

        this.setState(() => ({
            show_loader: true,

        }), () => {

            let url = router.url('language_translations.index', {
                'language_code': this.state.language_code,
                domain: this.props.data.admin_site.domain,
                page_size: 999,
                query_filter: this.state.working_category_id
            });

            // console.log('url', url)

            API.get(onResponse, url);
        });
    }

    onModelChange = (data = undefined) => {
        this.setState({
            content: {
                ...this.state.content,
                body: data
            }
        })
    }

    openMediaLibrary = () => {
        if (this.mediaLibraryRef.current) {
            this.mediaLibraryRef.current.openModal();
        }
    };

    handleMediaSelect = (file) => {
        // this.setState({ selectedImage: file.url });
        this.setState({
            content: {
                ...this.state.content,
                backgroundImage: file.url
            }
        })
    };
    removeImage = () => {
        // this.setState({ selectedImage: null });
        this.setState({
            content: {
                ...this.state.content,
                backgroundImage: null
            }
        })
    };

    updateHeroContent = (target, value) => {
        this.setState({
            content: {
                ...this.state.content,
                [target]: value
            }
        })
    }

    render() {

        const { translation, language_codes, language_code, working_category, content, working_category_id, selectedImage } = this.state;
        const { active_site, admin_site, csrf_token, env, pages, redirect_to, routes, languages, language_term_translations } = this.props.data;

        const router = Router(routes);

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2>Hero Banner Editor</h2>
                    </div>
                </div>
                <hr />

                <div className="row">
                    <div className="col-12">

                        <form action={router.url('settings.banner.update', { domain: admin_site.domain })} method="POST">

                            <div className="card border mb-5" style={{ minHeight: '100%' }}>
                                <div className="card-header">

                                    <div className="row">
                                        {
                                            <div className="col-12 col-lg-4">
                                                <Select
                                                    name="banner_category"
                                                    options={this.props.organizationMenuHandler(['default', 'home'])}
                                                    defaultValue={this.state.working_category}
                                                    onChange={(category) => this.changeWorkingCategoryDirectory(category)}
                                                />
                                            </div>
                                        }

                                        <div className="col-12 col-lg-4 justify-content-end">
                                            <select
                                                name="language_code"
                                                className="custom-select form-control"
                                                value={language_code}
                                                onChange={(e) => this.selectLanguage(e.target.value)}
                                            >
                                                <option value="">Select a language</option>
                                                {language_codes.map((language) => (
                                                    <option key={language?.id} value={language?.code}>
                                                        {language?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>

                                </div>
                                {
                                    // !_.isEmpty(translations) &&
                                    <div className="card-body border-0">
                                        {
                                            <div key={working_category} className={`card border-0 mb-3`}>

                                                <div className="row">

                                                    {/* CONTENT */}
                                                    <div className="col-12 col-md-9">

                                                        <div className="card-body p-4">
                                                            <h3 className="font-weight-bold">{language_code?.toUpperCase()}</h3>
                                                            <hr className="mb-4" />

                                                            <div className="row mb-3">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <InputText
                                                                            name="banner_title"
                                                                            placeholder="Title"
                                                                            value={content?.title || ''}
                                                                            onChange={(event) => this.updateHeroContent('title', event.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <InputText
                                                                            name="banner_subtitle"
                                                                            placeholder="Subtitle"
                                                                            value={content?.subtitle || ''}
                                                                            onChange={(event) => this.updateHeroContent('subtitle', event.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <EditorComponent
                                                                            id={`${working_category}_${translation?.local}`}
                                                                            model={_.isEmpty(content?.body) ? undefined : content?.body}
                                                                            onModelChange={(data) => this.onModelChange(data)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* IMAGE */}
                                                    <div className="col-12 col-md-3">
                                                        <div className="accordion md-accordion mb-2" id="hero_banner_accordion">
                                                            <div className="card">
                                                                <div className="card-header grey lighten-3 rounded-0 p-0" id="headingOne">
                                                                    <a
                                                                        data-cy="banner_image_toggle"
                                                                        className={"d-flex justify-content-between align-items-center p-2 p-lg-3 collapsed"}
                                                                        data-toggle="collapse"
                                                                        data-target="#accordion_image"
                                                                        href={"#accordion_image"}
                                                                        aria-expanded="true"
                                                                        aria-controls="accordion_image"
                                                                    >
                                                                        <h4 className="mb-0 font-weight-bold">
                                                                            Background Image
                                                                        </h4>
                                                                        <i className="fa fa-lg fa-angle-down rotate-icon"></i>
                                                                    </a>
                                                                </div>

                                                                <div
                                                                    id="accordion_image"
                                                                    className="collapse show"
                                                                    aria-labelledby="headingOne"
                                                                    data-parent="#hero_banner_accordion"
                                                                >
                                                                    <div className="card-body">
                                                                        {(content?.backgroundImage || null) ? (
                                                                            // If an image is selected, show the image + Replace & Remove buttons
                                                                            <div>
                                                                                <div className="row mb-3">
                                                                                    <div className="col-12 p-0">
                                                                                        <img src={content.backgroundImage} className="card shadow-none img-fluid" alt="Selected" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12 p-0">
                                                                                        <button type="button" className="btn btn-warning btn-default" onClick={this.openMediaLibrary}>
                                                                                            Replace Image
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="mt-2 col-12 p-0">
                                                                                        <button className="btn btn-link btn-danger text-white" onClick={this.removeImage}>
                                                                                            Remove Featured Image
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            // If no image is selected, show the "Add Feature Image" button
                                                                            <div className="text-center">
                                                                                <div className="border p-3 d-flex justify-content-center align-items-center" style={{ height: "200px", backgroundColor: "#f8f9fa" }}>
                                                                                    <span className="text-muted">No image selected</span>
                                                                                </div>
                                                                                <button type="button" className="btn btn-primary mt-3" onClick={this.openMediaLibrary}>
                                                                                    Add Feature Image
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="card-footer d-flex justify-content-end">

                                    <InputHidden name="_token" defaultValue={csrf_token} />
                                    <InputHidden name="site_id" defaultValue={active_site.site.id} />
                                    <InputHidden name="redirect_to" defaultValue={redirect_to} />
                                    <InputHidden name="group" defaultValue={'hero_banner'} />
                                    <InputHidden name="locale" defaultValue={language_code} />
                                    <InputHidden name="key" defaultValue={working_category_id} />
                                    <InputHidden name="text" defaultValue={JSON.stringify(content)} />
                                    <InputHidden name="id" defaultValue={translation?.id} />
                                    <InputHidden name="language_term_id" defaultValue={translation?.language_term_id} />

                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                    // disabled={_.isEmpty(content)}
                                    >
                                        Save changes
                                    </button>

                                </div>

                            </div>

                        </form>

                        <MediaLibraryModal
                            ref={this.mediaLibraryRef}
                            routes={routes}
                            csrf_token={csrf_token}
                            admin_site={admin_site}
                            onSelect={(file) => this.handleMediaSelect(file)}
                        />

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HeroBannerEditor;
